<nav>
  <div class="logo-container">
    <img src="../assets/img/RG_logo.png">
    <p>Richard Glass</p>
  </div>

  <div class="route-container">
    <a routerLink="projects" routerLinkActive="bold">projects</a>
    <a routerLink="about" routerLinkActive="bold">about</a>
    <a routerLink="contact" routerLinkActive="bold">contact</a>
  </div>
</nav>

<div [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
