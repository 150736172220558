import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  // define project objects

  nwdata = {
    title: 'NW Data',
    context: [
      'NW Data was a website and hybrid mobile application that I developed during my final year thesis in Civil Engineering, working as a member of Networking Water, \
      a non-profit initiative formed by a team of researchers within Imperial.',
      'My thesis investigated the applicability of using citizen science to gather information about water supply networks in countries where data is scarce.',
      'To achieve this, we (Networking Water) needed a tool that volunteers in Peru and Nepal could use to report on their water supply. This became NW Data.',
    ],
    challenges: [
      'Learning new languages and absorbing an overload of information from documentation and forums.',
      'Implementing logic in native JavaScript to handle toggling of modals and processing forms before making an AJAX request.',
      'Working with Amazon Web Services (AWS).',
    ],
    sentiment: [
      'This was my first real exposure to software development and I loved the experience.',
      'I knew little about fundamental software development concepts at the start of \
      this project which led to several learning opportunities throughout. \
      You only really appreciate the power of version control after you have about 20 folders of your project all named some variation of nwdata-new.',
      'Following the completion of my degree, I continued to develop this app to support ongoing research within Imperial and Networking Water.',
    ],
    img_src: 'assets/img/nw-data/NWDataFrame.png',
    summary: {
      orgs: 'Imperial College London, Networking Water',
      dates: 'March 2018 - July 2019',
      report_src: 'assets/reports/nw-data-thesis.pdf',
      tech: [
        'Native JS',
        'HTML, CSS',
        'Cordova',
        'Apache PhoneGap',
        'AWS',
        'GitHub',
      ],
    },
  };

  neobaby = {
    title: 'LWH neoBaby',
    context: [
      'This app was the product of a group design project during my CS degree - within which we worked with an external client to deliver an app according to their \
      specifications.',
      "Core app requirements can be split broadly into three areas: to serve as rich source information concerning Liverpool Women's Hospital (LWH) and neo-natal care, \
      for parents to create baby profiles to track their newborn's growth, and to allow parents to give feedback about the quality of care they received \
      during their stay in the NCU.",
    ],
    challenges: [
      'Working with continuously changing client requirements throughout the development process.',
      'Working with the client to ensure app design was appropriate.',
      'Collaborating efficiently with other group members.',
    ],
    sentiment: [
      "I was the team leader for this project, and have a lot of pride over how well we worked to deliver the completed app to the client. \
      This project was the first where I had the opportunity to really apply several of the development concepts that I'd learned directly from NW Data and \
      during my degree.",
      'As such, we made a concious decision to allocate a proportional amount of time to the initial phase of gathering requirements and planning. This helped us greatly \
      as we were able to discover potential issues and work around them whilst keeping in frequent contact with the client.',
      'Our client and module coordinator were very happy with the final product, as were we.',
    ],
    img_src: 'assets/img/neobaby/neobaby-home.png',
    summary: {
      orgs: "UCL, NHS, Liverpool Women's Hospital",
      dates: 'January 2020 - May 2020',
      report_src: 'assets/reports/neobaby-report.pdf',
      tech: ['Ionic', 'Angular', 'Typescript', 'HTML, SCSS', 'Azure', 'GitHub'],
    },
  };

  agora = {
    title: 'Agora',
    context: [
      'I developed this app for the summer project at UCL, where I had the opportunity to work with an external client (IBM).',
      'The project scope was to create a forum-based app that educational institutions could utilise to promote student discussion of \
      material covered in lectures.',
    ],
    challenges: [
      'Working with both an internal (UCL) and external (IBM) supervisor and ensuring that both parties were happy with the project scope.',
      'Developing a complex database schema to accurately represent the complex relationship between various entities.',
      'Implementing JSON Web Tokens as a method of managing persistent authentication states.',
    ],
    sentiment: [
      'I really enjoyed developing this app. Though I had an internal and external supervisor, I was given a lot of freedom to manage how I went about delivering the app \
      to the required specifications.',
      'The standout experience of this project for me was to really immerse myself within the backend development and designing of the MySQL database, as this was more complex \
      than any other backend development I had done up to this point.',
    ],
    img_src: 'assets/img/agora/agora-profile.png',
    summary: {
      orgs: 'UCL, IBM',
      dates: 'June 2020 - October 2020',
      report_src: 'assets/reports/agora-report.pdf',
      tech: [
        'Ionic',
        'Angular',
        'Typescript',
        'HTML, SCSS',
        'Node, Express',
        'MySQL',
        'GitHub',
      ],
    },
  };

  projects = [this.nwdata, this.neobaby, this.agora];

  constructor(private route: ActivatedRoute, private titleService: Title) {
    const page = this.route.snapshot.data['title'];
    var title: string;
    if (page) {
      title = page + ' | Richard Glass';
    } else {
      title = 'Richard Glass';
    }
    // const title = page + ' | Richard Glass';
    this.titleService.setTitle(title);
  }

  ngOnInit(): void {}
}
