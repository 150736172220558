<main>
    <div class="text">
        <h1>
            Get In Touch
        </h1>
        <p>Check out my CV <span><a target="_blank" href="../../../assets/CV/Richard-Glass-CV.pdf">here.</a></span></p>
        <p>ricardoglass@gmail.com</p>
        <p><a href="https://www.linkedin.com/in/richard-glass/" target="_blank">LinkedIn</a></p>
        <p><a href="https://github.com/richard-G" target="_blank">GitHub</a></p>
    </div>
    
</main>