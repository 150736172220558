import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  constructor(private titleService: Title, private route: ActivatedRoute) {
    const page = this.route.snapshot.data['title'];
    var title: string;
    if (page) {
      title = page + ' | Richard Glass';
    } else {
      title = 'Richard Glass';
    }
    // const title = page + ' | Richard Glass';
    this.titleService.setTitle(title);
  }

  ngOnInit(): void {}
}
