import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate,
} from '@angular/animations';

export const routeTransitionAnimations = trigger('routeAnimations', [
  transition('projects => about, projects => contact, about => contact', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
      }),
    ]),
    // define start state for page that is entering (far right, 0 opacity)
    query(':enter', [style({ right: '-100%', opacity: 0 })]),
    // ensure child component animations of the page being left happens before the leaving animation begins
    query(':leave', animateChild()),
    // group animations for the transition of page leaving/page entering
    group([
      query(':leave', [
        animate('300ms ease-in-out', style({ right: '100%', opacity: 0 })),
      ]),
      query(':enter', [
        animate('300ms ease-in-out', style({ right: '0%', opacity: 1 })),
      ]),
    ]),
    // trigger child animations of the new view after transition finishes
    query(':enter', animateChild()),
  ]),

  transition('about => projects, contact => projects, contact => about', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        width: '100%',
      }),
    ]),
    // define start state for page that is entering (far right, 0 opacity)
    query(':enter', [style({ left: '-100%', opacity: 0 })]),
    // ensure child component animations of the page being left happens before the leaving animation begins
    query(':leave', animateChild()),
    // group animations for the transition of page leaving/page entering
    group([
      query(':leave', [
        animate('300ms ease-in-out', style({ left: '100%', opacity: 0 })),
      ]),
      query(':enter', [
        animate('300ms ease-in-out', style({ left: '0%', opacity: 1 })),
      ]),
    ]),
    // trigger child animations of the new view after transition finishes
    query(':enter', animateChild()),
  ]),
]);
