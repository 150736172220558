<main>
    <div class="text">
        <h1>Overview</h1>
        <p>
            Since transitioning into the computer science field I've worked on several projects. Each of these has furthered my understanding and proficiency in applying various software development concepts and languages.
        </p>
    </div>

    <ng-container *ngIf="projects">
        <div class="project" *ngFor="let project of projects">
            <h2>{{ project.title }}</h2>

            <div class="container">
                <div class="content">
                    <div class="text">
                        <h4>
                            Context
                        </h4>
                        <p *ngFor="let paragraph of project.context">
                            {{ paragraph }}
                        </p>

                        <h4>
                            Particular Challenges and Learning Experiences
                        </h4>
                        <ul>
                            <li *ngFor="let challenge of project.challenges">
                                {{ challenge }}
                            </li>
                        </ul>

                        <h4>
                            Project Sentiment
                        </h4>
                        <p *ngFor="let paragraph of project.sentiment">
                            {{ paragraph }}
                        </p>
                    </div>
                </div>

                <div class="right-container">
                    <div class="info-box">
                        <h3>
                            Summary
                        </h3>

                        <p class="label">
                            Organisations and Institutions
                        </p>
                        <p class="value">
                            {{ this.project.summary.orgs }}
                        </p>

                        <p class="label">
                            Dates
                        </p>
                        <p class="value">
                            {{ this.project.summary.dates }}
                        </p>

                        <p class="label">
                            Report PDF
                        </p>
                        <a class="value" target="_blank" [href]="project.summary.report_src">
                            Available Here.
                        </a>

                        <p class="label">
                            Languages, Frameworks and Services Used
                        </p>
                        <ul class="value">
                            <li *ngFor="let instance of project.summary.tech">
                                {{ instance }}
                            </li>
                        </ul>
                    </div>
                    
                    <img [src]="project.img_src">
                </div>
            </div>
        </div>
    </ng-container>
    
</main>