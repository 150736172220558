<main>
    <div class="text">
        <h1>
            An Introduction
        </h1>
        <p>
            Welcome to my website! My name - as you may have guessed - is Richard. I'm an MSc CS grad from UCL and I love to code things. 
            I've set up this website so I can talk a bit about some of my projects and personal interests. 
        </p>
        <h1>
            Why CS?
        </h1>
        <p>
            How did I first get into computer science I hear you ask? 
            Well I was in the final year of my undergraduate Civil Engineering degree at Imperial
            when I chose a final thesis that required me to develop a simple website and mobile app to aid in data collection. 
            Instantly I was hooked. I loved coding, I loved learning about coding, and I loved talking about coding to anyone who would listen. 
            While I liked Civil Engineering, this experience I had developing the app was something I'd never felt before - 
            I felt intellectually stimulated and remember several instances where, at the cusp of falling asleep, suddenly a solution to a problem would come to me and 
            I'd jump out of bed and open up my laptop. 
        </p>
        <p>
            At some point during my thesis I realised that I couldn't simply go into the Civil Engineering field after finding my passion in coding, but 
            I also knew that I didn't want to jump straight into a CS job without taking the time to learn more about the field. I fell in 
            love with the learning process and I felt that I would really benefit from a one-year Masters course to learn some of the fundamentals of CS.
        </p>
        

        <h1>
            University
        </h1>
        <p>
            So I got applying to universities and I was accepted by my first choice, UCL. This course didn't begin until the next academic year, and I used my gap year
            as an opportunity to fully immerse myself into the world of computer science. This included reading books, developing personal projects, taking online courses 
            on Codecademy, and continuing to develop the app from my undergraduate thesis to support ongoing research within Imperial.
        </p>
        <p>
            I started at UCL in September 2019, and I took full advantage of the opportunity to meet other students on the course who shared my passion. It was a great experience
            conversing with peers that also wanted to talk about CS and share their own personal journeys that led to them transitioning from their undergraduate field to Computer 
            Science. 
            I enjoyed every module without exception and believe this degree has been invaluable to furthering my understanding of computer science concepts, particularly
            those relating to software engineering. 
        </p>
        <p>
            <!-- TODO: more about UCL? -->
        </p>
        <h1>
            What's Next?
        </h1>
        <p>
            Following the completion of my degree in September, I took some time to further develop some of my own projects (including this website) and put into practise 
            some of the software engineering concepts that I'd learned over the duration of my degree. 
        </p>
        <p>  
            Currently, I'm now looking to work in software development at a company that shares my approach to building projects and passion for the field.
        </p>
    </div>
    
</main>