import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  constructor(private route: ActivatedRoute, private titleService: Title) {
    const page = this.route.snapshot.data['title'];
    var title: string;
    if (page) {
      title = page + ' | Richard Glass';
    } else {
      title = 'Richard Glass';
    }
    // const title = page + ' | Richard Glass';
    this.titleService.setTitle(title);
  }

  ngOnInit(): void {}
}
