import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ProjectsComponent } from './pages/projects/projects.component';

const routes: Routes = [
  {
    path: 'about',
    component: AboutComponent,
    data: { animationState: 'about', title: 'About' },
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    data: { animationState: 'projects', title: 'Projects' },
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: { animationState: 'contact', title: 'Contact' },
  },
  {
    path: '',
    redirectTo: '/projects',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: ProjectsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
